


.pt-5{
    padding-top: 5px;
}
.pb-5{
    padding-bottom: 5px;
}
.pt-10{
    padding-top: 10px;
}
.pb-10{
    padding-bottom: 10px;
}
.py-10{
    padding-top: 10px;
    padding-bottom: 10px;
}

.text-center{
    text-align: center;
}

.swal2-container {
  z-index: 6000;
}
.py-20{
    padding:20px 0;
}
.mt-20{
    margin-top:20px;
}
    